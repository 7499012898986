<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <h1>
            {{ $t("Share additional income information") }}
          </h1>
          <p class="fw-medium text-gray pb-4">
            {{
              $t(
                "Because we cannot verify your income via online banking , we require additional documents and information."
              )
            }}
          </p>
          <Spinner v-if="!mounted"></Spinner>
          <div v-else>
            <TextInput
            name="personalMonthlyIncome"
            :label="$t('Gross monthly income')"
              v-model="form.personalMonthlyIncome"
              rules="required|numeric"
            />

            <TextInput
              name="employer_name"
              :label="$t('Name of employer')"
              v-model="form.employer_name"
              rules="required"
            />

            <TextInput
              name="hr_contact_person"
              :label="$t('HR department  - Name contact person')"
              v-model="form.hr_contact_person"
              rules="required"
            />

            <TextInput
              name="hr_email_address"
              :label="$t('HR department - Email address ')"
              v-model="form.hr_email_address"
              rules="required"
            />

            <TextInput
              name="hr_phone_number"
              :label="$t('HR department - Phone number ')"
              v-model="form.hr_phone_number"
              rules="required"
            />
          </div>

          <div class="mt-3">
            <small class="text-danger" v-if="getErrors.tenant">{{
              getErrors.tenant
            }}</small>
            <small class="text-danger" v-if="getErrors.address">{{
              getErrors.address
            }}</small>
          </div>

          <button
            class="btn btn-primary btn-block mt-4 mb-5"
            :disabled="!shouldSubmit"
            @click="submit"
          >
            {{ $t("Next step") }}
            <b-spinner
              v-if="getLoadings.tenant"
              small
              label="Spinning"
              variant="white"
            ></b-spinner>
          </button>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="step__rightSection mx-auto">
        <RightPoints active="5" />
      </div>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import RightPoints from "./RightPoints.vue";
import TextInput from "../../../components/ui/form/controls/TextInput.vue";
import Spinner from "../../../components/ui/Spinner.vue";

export default {
  data() {
    return {
      countries: [],
      institutes: [],
      instituteLoading: false,
      submitting: false,
      form: {
        personalMonthlyIncome: "",
        employer_name: "",
        hr_contact_person: "",
        hr_email_address: "",
        hr_phone_number: "",
      },
      submitted: true,
      error: "",
      mounted: false,
      loadingAddress: false,
    };
  },
  components: {
    AuthLayoutContent,
    RightPoints,
    TextInput,
    Spinner,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
    shouldSubmit() {
      let flag =
        this.form.personalMonthlyIncome &&
        this.form.employer_name &&
        this.form.hr_contact_person &&
        this.form.hr_email_address &&
        this.form.hr_phone_number;

      return flag && !this.getLoadings.tenant && !this.loadingAddress;
    },
  },
  async mounted() {
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
    }

    let tenant = this.tenant;
    console.log("this.user", this.tenant);

    this.form.personalMonthlyIncome = tenant.personalMonthlyIncome;
    this.form.employer_name = tenant.employer_name;
    this.form.hr_contact_person = tenant.hr_contact_person;
    this.form.hr_email_address = tenant.hr_email_address;
    this.form.hr_phone_number = tenant.hr_phone_number;
    this.mounted = true;
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    customLabel({ name }) {
      return name;
    },
    async submit() {
      console.log("ff", this.form);

      if (!this.shouldSubmit) {
        return;
      }

      await this.updateTenant(this.form);
      this.$router.push({
        name: "ExpatStepFallbackStep2",
        params: { tenant_id: this.tenant.id },
      });
    },
    onPartialData(data) {
      console.log("data", data);
    },
    async inputChange() {
      // if (this.form.zipcode && this.form.houseNr) {
      //   this.form.address_value = "Loading....";
      //   this.loadingAddress = true;
      //   try {
      //     let address = await this.getAddress({
      //       zipcode: this.form.zipcode,
      //       house_number: this.form.houseNr,
      //       house_ad: this.form.houseAd,
      //     });
      //     this.form.address_value = `${address.street} ${this.form.houseNr} ${
      //       this.form.houseAd ?? ""
      //     } ${address.city}`;
      //     this.form.address = address;
      //   } catch (error) {
      //     this.form.address_value = "";
      //   }
      //   this.loadingAddress = false;
      // }
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
